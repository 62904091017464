import {
    Text,
    Button,
    Stack,
    Stepper,
    Group,
    Container,
    Space,
    Loader,
    Paper,
    Center,
    Image,
    Alert
} from "@mantine/core";
import {ThemeProvider} from "./ThemeProvider";
import {useEffect, useState} from "react";
import {
    DepositSetAccountAsync, DepositSetAsFailedAsync,
    DepositSetAsPaidAsync,
    DepositSetAsTimeoutAsync,
    getAvailableAccounts,
    getDeposit
} from "./services/PaymentService";
import {
    IconCircleCheck,
    IconMailOpened,
    IconShieldCheck,
    IconBuildingBank,
    IconCashBanknote, IconInfoCircle
} from '@tabler/icons-react';
import SelectInputBox from "./components/SelectInputBox";
import {DepositModel} from "./models/DepositModels";
import {AccountModel, BankModel} from "./models/AccountModels";
import {Pay} from "./components/Pay";
import React from "react";
import * as signalR from '@microsoft/signalr'
import {CcPay} from "./components/CcPay";
import {ConfirmedMsgComponent} from "./components/ConfirmedMsgComponent";
import {DeclinedMsgComponent} from "./components/DeclinedMsgComponent";
import {AwaitingConfirmationMsgComponent} from "./components/AwaitingConfirmationMsgComponent";
import {DepositInfoComponent} from "./components/DepositInfoComponent";
import {decryptData} from "./services/encryptionHelper";

export const amountFormatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
});
export default function App() {
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const [paymentToken, setPaymentToken] = useState("");

    const [deposit, setDeposit] = useState<DepositModel | null>(null);
    const [accounts, setAccounts] = useState<AccountModel[]>([] as AccountModel[]);
    const [confirmedBankName, setConfirmedBankName] = useState("");
    const [selectedAccount, setSelectedAccount] = useState<AccountModel>({} as AccountModel);
    const [selectedBankName, setSelectedBankName] = useState("");
    const [isTimeout, setIsTimeout] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSetPaidLoading, setIsSetPaidLoading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [connection, setConnection] = useState<null | signalR.HubConnection>(null);
    const [logoPath, setLogPath] = useState<string>('');


    const fetchDeposit = () => {
        let tokenFromUrl = window.location.pathname.substring(5);
        setPaymentToken(tokenFromUrl)
        getDeposit(tokenFromUrl).then(res => {
            if (res.success) {
                // var decryptedData =decryptData(res.data ,process.env.REACT_APP_PAYMENT_SECURITY_KEY as string)
                // console.log(decryptedData)
                //
                // var data = JSON.parse(decryptedData) as DepositModel
                // console.log(data.amount)
                // console.log(data)
                setDeposit(res.data as DepositModel)
                // if (window.location.pathname.includes("?status=failed"))xxk
                setIsLoading(false)
            }

        }).catch(err => {
            console.log(err)
        }).finally(() => setIsLoading(false))

    }

    useEffect(() => {
        let tokenFromUrl = window.location.pathname.substring(5);
        setPaymentToken(tokenFromUrl)
        getAvailableAccounts(tokenFromUrl).then(res => {
            if (res.success) {
                setAccounts(res.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {

        setLogPath("")
        if (process.env.REACT_APP_CUSTOM_LOGO_BY_INTG == 'true' && deposit?.integration!=null) {
            if (deposit?.integration?.domain.includes("ultrabet"))
                setLogPath(process.env.REACT_APP_LOGO_ULTRA ?? "")
            else if (deposit?.integration?.domain.includes("setrabet"))
                setLogPath(process.env.REACT_APP_LOGO_SETRA ?? "")
            else if (deposit?.integration?.domain.includes("smartbahis"))
                setLogPath(process.env.REACT_APP_LOGO_SMART ?? "")
            else if (deposit?.integration?.domain.includes("betkolik"))
                setLogPath(process.env.REACT_APP_LOGO_BETKOLIK ?? "")
            // else
            //     setLogPath("/logo.png")
        }else
            setLogPath("/logo.png")


        if (deposit) {
            if (deposit?.depositStatusId === 3) { //confirmed
                setActive(2)
                redirectToIntegrationUrl()
            } else if (deposit?.depositStatusId === 5) { //timeout
                setAsTimeOut()
                redirectToIntegrationUrl()
            } else if (deposit?.depositStatusId === 2) //awaiting confirmation
                setActive(2)
            else if (deposit?.depositStatusId === 8)//Failed
            {
                handleSetAsFailedAsync()
                setActive(2)
            } else if (deposit?.depositStatusId === 4) { //declined
                redirectToIntegrationUrl()
            } else if (deposit?.depositStatusId === 1 && deposit?.account) { //waiting for deposit and account is chosen
                if (deposit?.account?.accountGroupId == 7)
                    setSelectedAccount({
                        accountGroupId: 7,
                        name: deposit.receiverAccName,
                        accountNumber: deposit.receiverAccNumber,
                    } as AccountModel)
                else if (deposit?.account?.accountGroupId == 8)
                    setSelectedAccount({
                        accountGroupId: 8,
                        name: deposit.receiverAccName,
                        accountNumber: deposit.receiverAccNumber,
                    } as AccountModel)
                else if (deposit?.account?.accountGroupId == 9 ||deposit?.account?.accountGroupId == 10)
                    setSelectedAccount({
                        accountGroupId: deposit?.account?.accountGroupId,
                        name: deposit.receiverAccName,
                        accountNumber: deposit.receiverAccNumber,
                    } as AccountModel)
                else {

                    setSelectedAccount(deposit?.account)
                }
                setActive(1)
            } else if (deposit.depositStatusId === 8) {

            } else if (deposit.account) {
                if (deposit.account?.accountGroupId == 7) {
                    setSelectedAccount({
                        accountGroupId: 7,
                        name: deposit.receiverAccName,
                        accountNumber: deposit.receiverAccNumber,
                    } as AccountModel)
                } else if (deposit.account?.accountGroupId == 8) {
                    setSelectedAccount({
                        accountGroupId: 8,
                        name: deposit.receiverAccName,
                        accountNumber: deposit.receiverAccNumber,
                    } as AccountModel)
                } else
                    setSelectedAccount(deposit?.account)

                setActive(1)
            }
        }
    }, [deposit])

    const redirectToIntegrationUrl = () => {
        setTimeout(() => {
            if (deposit?.redirectUrl != null && deposit.redirectUrl?.length > 1) {
                window.location.replace(deposit.redirectUrl)
            } else {
                if (!deposit?.integration?.domain.startsWith("http"))
                    window.location.replace("https://" + deposit?.integration?.domain)
                else
                    window.location.href = deposit.integration?.domain
            }
        }, 3000)
    }

    useEffect(() => {
        const con = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL + "/notification-hub",
                {skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets})
            .withAutomaticReconnect([1000, 1000, 3000, 5000, 10000, 15000, 20000])
            .build();
        setConnection(con)
    }, []);
    useEffect(() => {
        let tokenFromUrl = window.location.pathname.substring(5);
        setPaymentToken(tokenFromUrl)
        getDeposit(tokenFromUrl).then(res => {
            if (res.success) {
                // var decryptedData =decryptData(res.data ,process.env.REACT_APP_PAYMENT_SECURITY_KEY as string)
                // var data = JSON.parse(decryptedData) as DepositModel
                // setDeposit(data)
                // var decryptedData =decryptData(res.data ,process.env.REACT_APP_PAYMENT_SECURITY_KEY as string)
                // console.log("decrypteddata: ")
                // console.log(decryptedData)

                // var data = JSON.parse(decryptedData)
                setDeposit(res.data)
            }
            if (connection) {
                connection.start().then(() => {
                    connection.on("deposit-updated-" + res.data.id, msg => {
                        fetchDeposit();
                    })
                }).catch((error) => console.log(error));
            }
            // if (depositStatus === 3 || depositStatus === 4 || depositStatus === 6)
            //     setDeposit(null)
            // else
        }).catch(err => {
            console.log(err)
        }).finally(() => setIsLoading(false))
        return () => {
            connection?.stop()
        };
    }, [connection]);
    useEffect(() => {
        if (deposit?.account?.accountGroupId != 7)
            if (selectedBankName?.length > 0) {
                if (deposit?.paymentWayId == 2 && deposit?.amount >= 100000) {
                    let acc = accounts.find(x => x.bank?.name.toLowerCase() === selectedBankName.toLowerCase())
                    setSelectedAccount(acc!);
                } else {
                    if (deposit?.paymentWayId === 2){
                     let randomAcc =accounts[Math.floor(Math.random() * accounts.length)]
                        setSelectedAccount(randomAcc)
                    }
                    else {
                        if (selectedBankName === "Papara IBAN Oto") {
                            let acc = accounts.find(acc => acc?.accountGroupId === 8)
                            setSelectedAccount(acc!);
                        } else {
                            let acc = accounts.find(acc => acc?.accountGroup?.name === selectedBankName)
                            setSelectedAccount(acc!);
                        }
                    }
                }
            } else
                setActive(0)
    }, [selectedBankName]);

    const handleSelectAccount = () => {
        setIsBtnLoading(true)
        DepositSetAccountAsync({
            depositId: deposit!.id,
            accountId: selectedAccount?.id,
            paymentSecurityKey: process.env.REACT_APP_PAYMENT_SECURITY_KEY!.toString()
        })
            .then(res => {
                if (res.success) {
                    fetchDeposit()
                    setActive(1)
                } else
                    console.log(res.message)

            }).catch(err => console.log(err))
            .finally(() => setIsBtnLoading(false))
    }
    const handleSetAsPaidAsync = () => {
        setIsSetPaidLoading(true)
        DepositSetAsPaidAsync({
            depositId: deposit!.id,
            depositStatusId: 2,
            paymentSecurityKey: process.env.REACT_APP_PAYMENT_SECURITY_KEY!.toString()
        })
            .then(res => {
                setActive(3)
                fetchDeposit()
            }).finally(() => {
            setIsSetPaidLoading(false)
        })
    }
    const handleSetAsFailedAsync = () => {

        DepositSetAsFailedAsync({
            depositId: deposit!.id,
            depositStatusId: 8, //failed
            paymentSecurityKey: process.env.REACT_APP_PAYMENT_SECURITY_KEY!.toString()
        }).then(res => {
            setActive(3)
            fetchDeposit()
        }).catch(err => console.log(err))
    }
    const handleSetAsTimeoutAsync = () => {
        if (!isTimeout) {
            DepositSetAsTimeoutAsync({
                depositId: deposit!.id,
                depositStatusId: 5,
                paymentSecurityKey: process.env.REACT_APP_PAYMENT_SECURITY_KEY!.toString()
            })
                .then(res => {
                    setAsTimeOut()
                }).catch(err => console.log(err))
        }
    }
    const setAsTimeOut = () => {
        setIsTimeout(true)
        setActive(1)
    }


    return (
        <ThemeProvider>
            <>
                <Center mt={'2rem'} mb={'2rem'}>
                    {logoPath.length <= 0 ? (
                        <Center mt={50}>
                            <Loader/>
                        </Center>
                    ) : (

                        <Image
                            width={'20.875rem'}
                            height={'6.8rem'}
                            fit={"contain"}
                            style={{objectFit: 'contain'}}
                            src={logoPath ?? "/logo.png"}

                        />
                    )}
                </Center>

                {isLoading ? (
                    <Center mt={50}>

                        <Loader/>
                    </Center>
                ) : <>
                    {deposit != null ? (
                        <Container style={{borderWidth: '2px', borderColor: 'white', maxWidth: 650}} size="65%" mt={30}
                                   p={5} px={10}>
                            {deposit.paymentWayId === 3 ? (
                                <>
                                    {deposit.depositStatusId === 1 ? (
                                        <>
                                            <DepositInfoComponent data={deposit}/>
                                            <CcPay deposit={deposit}/>
                                        </>
                                    ) : (deposit.depositStatusId === 3 ? (
                                        <><ConfirmedMsgComponent/>
                                        </>
                                    ) : <><DeclinedMsgComponent/></>)}
                                </>
                            ) : (
                                <>
                                    <>
                                        <Stepper orientation={"horizontal"} active={active} onStepClick={setActive}>
                                            <Stepper.Step color={"#4c0e8d"} icon={<IconBuildingBank size="1.1rem"/>}
                                                          allowStepSelect={false}
                                                          allowStepClick={false}
                                                          label={deposit.paymentWayId === 1 ? "Hesap Seçimi" : "Banka Seçimi"}
                                                          description={deposit.paymentWayId === 1 ? "Hesap Seçiniz" : "Banka Seçiniz"}/>
                                            <Stepper.Step color={"#4c0e8d"} icon={<IconMailOpened size="1.1rem"/>}
                                                          allowStepSelect={false}
                                                          allowStepClick={false} label="Ödeme"
                                                          description="Ödeme Yapınız"/>
                                            <Stepper.Step color={"#4c0e8d"} icon={<IconShieldCheck size="1.1rem"/>}
                                                          allowStepSelect={false}
                                                          allowStepClick={false} label="Sonuç"
                                                          description="Sonuç"/>
                                        </Stepper>
                                    </>
                                    <Space h="xl"/>
                                    <Text
                                        // variant="gradient"
                                        // gradient={{from: 'purple', to: 'blue', deg: 45}}
                                        // color={"gray"}
                                        sx={{fontFamily: 'Greycliff CF, sans-serif'}}
                                        ta="center"
                                        fz="xl"
                                        fw={700}
                                    >
                                        {deposit.customerFullName}
                                    </Text>
                                    <Text align={'center'}>{amountFormatter.format(deposit.amount)}</Text>

                                    <Space h="xl"/>
                                    {active === 0 ? (
                                        <>
                                            <SelectInputBox selectedBankName={selectedBankName} deposit={deposit}
                                                            accounts={accounts}
                                                            setSelectedBankName={setSelectedBankName}/>
                                            <Space h="xl"/>
                                            <Group position="center" mt="xl">
                                                {/*<Button variant="default" onClick={prevStep}>Back</Button>*/}
                                                <Button variant="gradient"
                                                        gradient={{from: 'purple', to: 'blue'}}
                                                        onClick={() => handleSelectAccount()}
                                                        loading={isBtnLoading}
                                                        disabled={!(selectedBankName?.length > 0) && selectedAccount !== null}>Ödemeye
                                                    Geç</Button>
                                            </Group>
                                        </>
                                    ) : active === 1 ? (
                                        deposit.depositStatusId === 5 ? (
                                            <>
                                                <Paper color="error" shadow="lg" radius="md" p="lg">
                                                    <Center>

                                                        <Text color="error">
                                                            Ödeme Zaman Aşımına Uğradı
                                                        </Text>
                                                    </Center>
                                                </Paper>
                                            </>
                                        ) : (
                                            <>
                                                <Pay handleSetAsTimeoutAsync={handleSetAsTimeoutAsync} deposit={deposit}
                                                     account={selectedAccount}/>
                                                {/*<Space h="xl"/>*/}
                                                <Space h="xl"/>

                                                <Center>
                                                    <Button disabled={isSetPaidLoading} loading={isSetPaidLoading}
                                                            onClick={() => handleSetAsPaidAsync()} variant="gradient"
                                                            gradient={{from: 'purple', to: 'blue'}}>Ödemeyi
                                                        Gerçekleştirdim</Button>
                                                </Center>
                                                <Space h="xl"/>

                                            </>
                                        )
                                    ) : (
                                        <>
                                            {deposit.depositStatusId === 2 ? (
                                                <AwaitingConfirmationMsgComponent/>
                                            ) : deposit.depositStatusId === 3 ? (
                                                <ConfirmedMsgComponent/>
                                            ) : deposit.depositStatusId === 4 ? (
                                                <DeclinedMsgComponent/>
                                            ) : <></>
                                            }
                                            <Space h="xl"/>
                                            <Space h="xl"/>
                                            <Center>
                                                <Loader
                                                    color={deposit.depositStatusId === 3 ? "green" : deposit.depositStatusId === 4 ? "red" : "blue"}
                                                    variant="dots"/>
                                            </Center>
                                        </>
                                    )}

                                </>
                            )}

                        </Container>
                    ) : (
                        <Center mt={'5%'}>
                            <Paper shadow="lg" radius="md" p="lg">
                                <Text></Text>
                                <Text>
                                    Talep Bulunamadı. Geçersiz Ödeme Anahtarı!
                                </Text>
                            </Paper>
                        </Center>
                        // <Loader size="xl" variant="dots"/>
                    )}
                </>}

            </>
        </ThemeProvider>
    );
}
